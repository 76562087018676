<template>
  <div id="profile">
    <p>
      To visit PCT - Tool Click on below link <br /><br />
      <a href="" v-on:click="signIn()">Tableau dashboard</a>.
    </p>

    <!-- <h1>My User Profile (ID Token Claims)</h1>
    <p>Below is the information from your ID token.</p>
    <table>
      <thead>
        <tr>
          <th>Claim</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(claim, index) in claims" :key="index">
          <td>{{ claim.claim }}</td>
          <td :id="'claim-' + claim.claim">{{ claim.value }}</td>
        </tr>
      </tbody>
    </table> -->
    <!-- <iframe src="https://10az.online.tableau.com/#/site/morningstar/views/PortfolioConstruction/SectorTrusts?:iid=1" frameborder="0" width="700" height="500"
 style="overflow:hidden" sandbox="allow-top-navigation allow-scripts allow-forms"></iframe>
    <div class="container">
          <iframe
            :src="`https://10az.online.tableau.com/#/site/morningstar/views/PortfolioConstruction/SectorTrusts?:iid=1`"
            width="100%"
            height="100"
            frameborder="0" >
           </iframe>
    </div> -->
    <!-- <div><iframe src="https://10az.online.tableau.com/#/site/morningstar/views/PortfolioConstruction/SectorTrusts?:iid=1"></iframe></div> -->
  </div>
</template>

<script>
//import { MdsButton } from "@mds/button";

export default {
  name: "ProfilePage",
  // components: {
  //   MdsButton,
  // },
  data() {
    return {
      claims: [],
    };
  },
  async created() {
    this.claims = await Object.entries(await this.$auth.getUser()).map(
      (entry) => ({ claim: entry[0], value: entry[1] })
    );
  },
  methods: {
    signIn() {
      //this.$auth.signInWithRedirect({ originalUri: PATH_TO_PROTECTED_ROUTE });
      //window.location.href = 'https://10az.online.tableau.com/#/site/morningstar/views/PortfolioConstruction/SectorTrusts?:iid=1';
      window.open(
        "https://10az.online.tableau.com/#/site/morningstar/views/PortfolioConstruction/SectorTrusts?:iid=1",
        "_blank"
      );
      //window.open("https://morningstar.okta.com/app/tableauonline/exk366nm7ow9plQD64x7/sso/saml ", "_blank");
    },
  },
};
</script>
