import OktaSignIn from '@okta/okta-signin-widget'
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { OktaAuth } from '@okta/okta-auth-js'

const oktaSignIn = new OktaSignIn({
  baseUrl: 'https://morningstar.okta.com/',
  clientId: '0oa6d0bnu0VmAMilw4x7',
  redirectUri: 'https://mp-pct-stg.mp04497.easn.morningstar.com/login/callback',
  authParams: {
    pkce: true,
    issuer: 'https://morningstar.okta.com/',
    display: 'page',
    scopes: ['openid', 'profile', 'email']
  }
});

//developer account details : 
// baseUrl: 'https://dev-05850819.okta.com',
// clientId: '0oa3yri6jg7vXaWNW5d7',

// /http://localhost:8080/login/callback

//Admin account details : 
// 'https://morningstar.okta.com',
//   clientId: '0oa6d0bnu0VmAMilw4x7',



const oktaAuth = new OktaAuth({
  issuer: 'https://morningstar.okta.com/',
  clientId: '0oa6d0bnu0VmAMilw4x7',
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email']
})

export { oktaAuth, oktaSignIn };