<template>
  <div class="LoginPage">
    <div id="okta-signin-container"></div>
  </div>
</template>

<script>
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import { oktaSignIn } from "../okta";

export default {
  name: "LoginPage",
  mounted: function () {
    this.$nextTick(function () {
      oktaSignIn.showSignInAndRedirect(
        { el: "#okta-signin-container" }
      );
      
    });
  },
  unmounted() {
    // Remove the widget from the DOM on path change
    oktaSignIn.remove();
  },
};
</script>
